@import "../variable.scss";
.sidenav-main {
    margin-left: 230px; /* Same as the width of the sidenav */
    // font-size: 28px; /* Increased text to enable scrolling */
    padding: 100px 50px 50px;
    background: #f7f7f7;
    min-height: 100vh;
    // height: 100%;
    // @include size1199{
    //     padding: 20px;
    // }
    @include size767 {
        margin-left: 200px;
    }
    .applications {
        .rownd-right-section-header-bar {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            flex-wrap: nowrap;
            .rownd-project-title-main {
                width: 55%;
                display: flex;
            }
            .rownd-theme-btns-main {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: initial;
                @include size767 {
                    flex: 1;
                }
            }
            .rownd-plan-pro-box {
                justify-content: flex-end;
                width: 100%;
                display: flex;
                span {
                    color: #000000;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            .rownd-test-app-with-btns-bar {
                display: flex;
                @include size767 {
                    margin-top: 15px;
                }
                .rownd-addapi-key-btns {
                    margin-top: 0;
                    margin-right: 10px;
                    .rownd-test-with-btn {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            &.rownd-test-app-with-main {
                @include size767 {
                    flex-direction: column;
                }
            }
        }
        .rownd-table-main-block {
            .add-application {
                background: #ffffff;
                padding: 30px;
                margin-bottom: 30px;
                @include size991 {
                    padding: 20px;
                }
                @include size767 {
                    overflow-y: auto;
                }
                form {
                    input {
                        border: none;
                        // padding: 0;
                        color: #000000;
                        font-size: 14px;
                        font-weight: normal;
                        // height: auto;
                        margin-bottom: 10px;
                        // @include size991{
                        //     font-size: 16px;
                        // }
                        // @include size767{
                        //     font-size: 14px;
                        // }
                    }
                    .hs-auth-error {
                        color: #131026;
                        font-size: 14px;
                        font-weight: normal;
                    }
                    ::placeholder {
                        color: #858990;
                        opacity: 0.65; /* Firefox */
                    }

                    :-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: #858990;
                    }

                    ::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: #858990;
                    }
                    .application-footer {
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                        padding-top: 30px;
                        .rownd-theme-btns-main {
                            margin-left: 15px;
                        }
                    }
                }
                .rownd-applications-upload-img {
                    .close-btn {
                        .col-md-12 {
                            display: flex;
                            justify-content: flex-end;
                        }
                        button {
                            border: none;
                            outline: none;
                            margin-bottom: 25px;
                            background: #854dff;
                            width: 30px;
                            height: 30px;
                            border-radius: 100px;
                            color: white;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                .rownd-logos-block {
                    width: 100%;
                    height: 105px;
                    border-radius: 4px;
                    overflow: hidden;
                    // border: 1px solid #dddddd;
                    margin-left: 20px;
                    background: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 6px;
                    margin: auto;
                    margin-bottom: 0px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
            .rownd-choose-file-main-block {
                padding: 30px 0;
                .rownd-choose-file-section {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #efefef;
                    padding-bottom: 30px;
                    width: 100%;
                    .hs-edit-img {
                        width: 80px;
                        height: 80px;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 1px solid #dddddd;
                        margin-right: 15px;
                        background: #fbfbff;
                        position: relative;
                        @include size575 {
                            width: 50px;
                            height: 50px;
                        }
                        &::before {
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    .hs-upload-user-file {
                        display: inline-block;
                        width: 100%;
                        word-break: break-word;
                        overflow: auto;
                    }
                }
            }
            .rownd-project-details-block-main {
                .rownd-project-title {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 15px;
                    h4 {
                        color: #000000;
                        font-size: 20px;
                        font-weight: normal;
                    }
                    .rownd-theme-btns-main {
                        padding: 6px 24px;
                        min-width: 120px;
                        font-size: 14px;
                    }
                }
                p {
                    color: #858990;
                    font-size: 16px;
                    line-height: 25px;
                }
                .rownd-application-details-header {
                    width: 100%;
                    align-items: center;
                    .rownd-counts-details-block-main {
                        display: flex;
                        // align-items: center;
                        // justify-content: flex-end;
                        // margin-right: -30px;
                        @include size1199 {
                            justify-content: space-between;
                        }
                        @include size991 {
                            margin-top: 10px;
                            align-items: end;
                        }
                        @include size767 {
                            margin-top: 15px;
                        }
                        ul {
                            li {
                                display: inline-flex;
                                align-items: center;
                                color: #858990;
                                font-size: 14px;
                                font-weight: normal;
                                padding: 5px 15px;
                                background: #f7f7f7;
                                @include size991 {
                                    margin-bottom: 5px;
                                    margin-right: 6px;
                                }
                                span {
                                    font-size: 20px;
                                    color: $theme-color-02;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
                .rownd-last-date-block {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: -15px;
                    span {
                        color: #000000;
                        font-size: 15px;
                        font-weight: normal;
                    }
                    .dropdown {
                        margin-left: 15px;
                        button {
                            background: transparent;
                            border: none;
                            padding: 0;
                            height: 33px;
                            display: flex;
                            align-items: center;
                            &::after {
                                content: unset;
                            }
                            span {
                                color: #828282;
                                font-size: 40px;
                                line-height: normal;
                                margin-top: 0px;
                                transform: rotate(90deg);
                                margin-right: -25px;
                                padding: 0;
                                width: auto;
                            }
                        }
                        .dropdown-menu {
                            padding: 0;
                            border: none;
                            background: #ffffff;
                            border: 1px solid #f3f3f3;
                            box-shadow: 0 0 17px 0 #f8f6ff;
                            top: -10px !important;
                            .dropdown-item {
                                padding: 8px 15px;
                                font-size: 15px;
                                font-weight: normal;
                                transition: all 0.3s ease-in-out;
                                &:hover {
                                    background: $theme-color-02;
                                    color: white !important;
                                }
                                &.edit {
                                    color: $theme-color-02;
                                }
                                &.delete {
                                    color: #424242;
                                }
                            }
                        }
                    }
                }
            }
            &.rownd-all-applications-block {
                margin-bottom: 30px;
                .add-application {
                    margin-bottom: 0px;
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 30px);
                    @include size1199 {
                        height: auto;
                        margin-bottom: 20px;
                    }
                }
                h5 {
                    color: #858990;
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 12px;
                }
                span {
                    font-size: 35px;
                    color: $theme-color-02;
                    font-weight: normal;
                }
                p {
                    color: #858990;
                    font-size: 16px;
                    font-weight: normal;
                }
                .rownd-refresh-block {
                    display: flex;
                    justify-content: flex-end;
                    height: 100%;
                    align-items: flex-end;
                    .rownd-refresh-btn {
                        background: transparent;
                        font-size: 16px;
                        color: #5b13df;
                        border: none;
                        font-weight: normal;
                    }
                }
            }
            .rownd-application-details-block {
                p {
                    color: #858990;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 25px;
                }
                .rownd-image-priview-block {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    @include size767 {
                        margin-top: 20px;
                    }
                    .rownd-logos-block {
                        width: 130px;
                        height: 105px;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 1px solid #dddddd;
                        margin-left: 20px;
                        background: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px;
                        margin: auto;
                        margin-bottom: 15px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                }
                .rownd-app-id-block-main {
                    display: inline-flex;
                    background: #f7f7f7;
                    align-items: center;
                    padding: 6px;
                    border-radius: 4px;
                    margin-bottom: 15px;
                    p {
                        color: $black;
                        font-size: 12px;
                        font-weight: normal;
                        line-height: normal;
                    }
                    .rownd-table-btns-block {
                        margin: 0;
                        padding: 0 5px;
                        margin-left: 30px;
                    }
                }
                .row {
                    .col-md-12 {
                        &:last-child {
                            margin-top: 20px;
                        }
                    }
                }
                ul {
                    li {
                        display: inline-flex;
                        align-items: center;
                        color: #858990;
                        font-size: 14px;
                        font-weight: normal;
                        padding: 5px 15px;
                        // background: #f7f7f7;
                        @include size991 {
                            margin-bottom: 5px;
                            margin-right: 6px;
                        }
                        span {
                            font-size: 20px;
                            color: $theme-color-02;
                            font-weight: normal;
                            padding-right: 10px;
                        }
                    }
                }
            }
            &.rownd-application-border-add {
                .add-application {
                    border: 2px solid white;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    border-radius: 6px;
                    &:hover {
                        border-color: $theme-color-02;
                    }
                }
            }
        }
        .rownd-add-another-app {
            font-size: 14px;
            font-weight: normal;
            color: #858990;
            outline: none;
            box-shadow: none;
            border: none;
            background: transparent;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 0.7;
            }
            .fa-plus {
                border: 2px solid $theme-color-02;
                border-radius: 100px;
                width: 21px;
                height: 21px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
                color: $theme-color-02;
            }
        }
    }
}

// Need some differing body margins in the unauthenticated view
.rownd-unauthenticated, .rownd-no-header-footer-sidenav {
    .rownd-body-main {
        background: #f7f7f7;
        .rownd-cover-main {
            padding-top: 65px;

            .rownd-main {
                margin-left: 0;
            }
        }
    }
}

.rownd-body-main {
    min-height: 100%;
    position: relative;
    .rownd-cover-main {
        padding-top: 80px;
        padding-bottom: 50px;
        .rownd-main {
            margin-left: 70px;
            transition: all 0.3s ease-in-out;
            .rownd-login-pages {
                padding: 80px 0;
                @include size991 {
                    padding: 80px 0;
                }
                @include size767 {
                    padding: 60px 0 40px;
                }
                .row {
                    align-items: center;
                }
                .signin-link {
                    padding: 10px 0px;
                }
                .rownd-login-page-main {
                    @include size767 {
                        margin-bottom: 35px;
                    }
                    .rownd-login-details {
                        .rownd-login-titel-main {
                            padding-bottom: 25px;
                            @include size767 {
                                padding-bottom: 15px;
                            }
                        }
                        p {
                            font-size: 16px;
                            color: #858990;
                            font-weight: normal;
                            padding-bottom: 25px;
                        }
                    }
                    .github-btn {
                        background: transparent;
                        border: 1px solid #dcdcdc;
                        height: 48px;
                        font-size: 16px;
                        color: #3e3e3e;
                        font-weight: normal;
                        border-radius: 0.25rem;
                        width: 100%;
                        text-align: start;
                        position: relative;
                        padding: 12px;
                        padding-left: 40px;
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                        // img{
                        //     width: 20px;
                        //     height: auto;
                        //     object-fit: contain;
                        //     object-position: center;
                        //     margin-right: 10px;
                        // }
                        &::before {
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            left: 10px;
                            margin: auto;
                            top: 0;
                            bottom: 0;
                        }
                        &.github {
                            &::before {
                                background: url(/media/images/git-icon.svg) no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                        &.google-oauth2 {
                            &::before {
                                background: url(/media/images/google-icon.svg) no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                        &.facebook {
                            &::before {
                                background: url(/media/images/facebook-icon.svg) no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                    }
                    .rownd-or-details-block {
                        padding: 20px 0;
                        p {
                            font-size: 16px;
                            color: #3e3e3e;
                            font-weight: normal;
                            text-align: center;
                            position: relative;
                            &::before {
                                position: absolute;
                                content: "";
                                right: 0;
                                /* left: 0; */
                                bottom: 0;
                                width: 45%;
                                height: 1px;
                                background-color: #d8d8d8;
                                margin: auto;
                                top: 0;
                            }
                            &::after {
                                position: absolute;
                                content: "";
                                left: 0;
                                bottom: 0;
                                width: 45%;
                                height: 1px;
                                background-color: #d8d8d8;
                                margin: auto;
                                top: 0;
                            }
                        }
                    }
                }
                .login-image-block {
                    @include size767 {
                        width: 80%;
                        margin: auto;
                    }
                }
                .rownd-blog-control-section {
                    .rownd-login-titel-main {
                        padding-bottom: 25px;
                    }
                    ul {
                        li {
                            font-size: 16px;
                            color: #858990;
                            font-weight: normal;
                            padding-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
    .rownd-loader-icon {
        width: 100%;
        height: 100vh;
        background: #030303;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        opacity: 0.7;
        img {
            width: 100px;
            height: auto;
            background-position: center;
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }
    }
}

.toast-messages {
    padding: 0px 15px;
    word-break: break-word;
    list-style-type: none;
}

.rownd-end-user-follow-main {
    background: #f7f7f7;
    .rownd-user-overview-header-main {
        display: flex;
        align-items: center;
        padding: 10px 0 50px;
        .rownd-user-pic {
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 1000px;
            background: white;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        .rownd-user-overview-details {
            margin-left: 30px;
            .rownd-project-title-main {
                margin-bottom: 6px;
            }
            p {
                font-size: 18px;
                color: #3e3e3e;
                font-weight: normal;
            }
        }
    }
    .rownd-all-section-cover-block {
        background: white;
        padding: 30px;
        margin-bottom: 30px;
    }
    .rownd-user-overview-page {
        padding: 40px 0;
        height: 100%;
        background: #f7f7f7;
        .rownd-email-section-main {
            width: 98%;
            border-bottom: 1px solid #e1e1e1;
        }
        .rownd-by-turning-block-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            border: 1px solid red;
            padding: 12px 15px;
            margin-bottom: 20px;
            font-size: 14px;
            p {
                display: flex;
                align-items: center;
                width: calc(100% - 40px);
                img {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                }
            }
            button {
                border: none;
                box-shadow: none;
                outline: none;
                background: transparent;
                display: flex;
                padding: 0;
                img {
                    width: 15px;
                    height: auto;
                }
            }
        }
        .rownd-back-btns {
            .rownd-back-arrow {
                font-size: 16px;
                color: #5b13df;
                height: 15px;
                display: inline-flex;
                align-items: center;
                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    object-position: center;
                    margin-right: 15px;
                }
            }
        }
    }
    .toggle.test-data-switch {
        // border: none;
        // width: 50px !important;
        // height: 35px !important;
        // border-radius: 50px;
        // background-color: transparent;
        border: none;
        width: 60px !important;
        height: 30px !important;
        border-radius: 50px;
        background-color: #5b13df;
        margin-right: 15px;
        span {
            border: none;
            padding: 0;
            border-radius: 50px;
        }
        .btn-primary {
            // background-color: $light-green;
            // border:1px solid $light-green;

            background-color: white;
            border: 1px solid white;
            width: 26px;
            height: 26px;
            margin-top: 2px;
            margin-left: 32px;
            &:active {
                // background-color: $light-green;
                background-color: white;
            }
        }
        .btn-danger {
            background-color: transparent;
            box-shadow: none;
        }
        .btn-default {
            width: 26px;
            height: 26px;
            margin-top: 2px;
            margin-right: 2px;
            margin-left: 2px;
        }
        .toggle-handle {
            &.btn-default {
                width: unset;
                height: unset;
                margin-top: unset;
                margin-right: unset;
                border: none !important;
            }
        }
        &.off {
            .btn-danger,
            .btn-default {
                background-color: #f2f2f2;
                border: 1px solid #e1e1e1;
            }
            background-color: #a6a6a6;
        }
    }
    .test-data-switch[disabled],
    .test-data-switch:disabled {
        background-color: #a6a6a6;
    }
}

.rownd-auth-check-banner {
    text-align: center;

    img {
        width: 100px;
        height: auto;
    }

    p {
        line-height: 1.5;
        padding: 20px;
    }
}
