@import '../../../variable.scss';

.hub-auth-v2__dark-mode {
  .pdc-modal-container {
    .pdc-modal-container__background {
      opacity: 0.4;
    }
    .pdc-modal {
      .pdc-modal__close {
        svg {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
      }
    }
  }
}

.pdc-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 1000000;
  padding-top: 88px;

  &.pdc-modal-container--bottom-sheet {
    .pdc-modal {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      margin: 0px;
      max-width: unset;
    }
  }

  .pdc-modal-container__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-black;
    opacity: 0.2;
  }

  .pdc-modal {
    position: relative;
    min-width: 200px;
    height: fit-content;
    max-height: calc(100% - 88px);
    overflow-y: auto;
    background-color: $white;
    padding: 32px;
    transition-duration: 0ms !important;
    transition: none !important;
    margin: 16px;

    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);

    border-radius: 15px;

    .pdc-modal__close {
      position: absolute;
      top: 14px;
      right: 16px;
      background-color: transparent;
      border: none;
      outline: none;
      height: 24px;
      width: 24px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}
