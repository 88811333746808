@import "../../../variable.scss";
.rownd-user-welcome-pages-main {
    .rownd-user-welcome-header {
        padding: 20px 5px;
        background: #000;
        width: 100%;
        position: fixed;
        z-index: 10000;
        .rownd-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            .rownd-logo {
                display: flex;
                opacity: 1;
                img {
                    width: auto;
                    height: 25px;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .rownd-welcome-header-right-side {
                display: flex;
                align-items: center;
                .rownd-user-img {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    margin-right: 20px;
                    border-radius: 100px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .rownd-learn-more-block {
                    display: flex;
                    font-size: 15px;
                    color: white;
                    position: relative;
                    padding-left: 20px;
                    background: transparent;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    &::after {
                        position: absolute;
                        content: "";
                        background: $theme-color-02;
                        height: 30px;
                        width: 1px;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }
}
.rownd-user-header-main {
    background: $theme-color;
    padding: 15px 0;
    .navbar {
        padding: 0;
        .rownd-back-btns {
            .rownd-table-btns-block {
                background: white !important;
                opacity: 1;
                color: $theme-color-02 !important;
            }
        }
        .rownd-user-logo-block {
            text-align: center;
            margin: auto;
            .navbar-brand {
                padding: 0;
                margin: 0;
                img {
                    width: 36px;
                }
            }
        }
    }
}
