@import "../../../variable.scss";

.sidenav {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 100px;
    left: 0;
    overflow-x: hidden;
    padding-top: 0px;
    background: white;
    width: 70px;
    transition: all 0.3s ease;
    ul {
        li {
            margin-bottom: 0;
            display: block;
            a {
                font-size: 15px;
                color: #565656;
                display: flex;
                align-items: center;
                font-weight: normal;
                letter-spacing: 1px;
                padding: 15px 15px 15px 17px;
                background: transparent;
                position: relative;
                transition: all 0.3s ease-in-out;
                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 4px;
                    background: white;
                    left: 0;
                    transition: all 0.3s ease-in-out;
                }
                @include size767 {
                    font-size: 13px;
                }
                i {
                    margin-right: 15px;
                    width: 20px;
                    height: auto;
                    display: flex;
                    @include size767 {
                        width: 16px;
                    }

                    &.rownd-apps-dashboard-nav {
                        border: 3px solid #333;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                .nav-text {
                    opacity: 0;
                    display: none;
                }
                &:hover {
                    background: #e5e5e5;
                    &::before {
                        background: #333;
                    }
                }
            }
            &.active {
                a {
                    font-weight: 500;
                    &.active {
                        background: #e5e5e5;
                        &::before {
                            background: black;
                        }
                    }
                }
            }
            // &:hover{
            //     a{
            //         color: $theme-color-02;
            //     }
            // }
        }
        .export-icon {
            transition: all 0.3s ease-in-out;
            // padding: 10px 0;
            // width: calc(100% - 14px);
            position: relative;
            &::after {
                position: absolute;
                right: 0;
                top: 0px;
                left: 0;
                content: "";
                background: #dfdfdf;
                width: 100%;
                height: 1px;
            }
            &:hover {
                background: #f7f7f7;
                span {
                    img {
                        opacity: 1;
                    }
                }
            }
            &.active {
                background: #f7f7f7;
                span {
                    img {
                        opacity: 1;
                    }
                }
            }
            span {
                transition: all 0.3s ease-in-out;
                img {
                    opacity: 0;
                    width: 18px;
                    margin-left: 5px;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

.rownd-sidenav-toggle {
    .rownd-body-main {
        .rownd-header-main {
            .sidebar-menu-icon {
                img {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .rownd-cover-main {
        .sidenav {
            width: 230px;
            ul {
                li {
                    a {
                        .nav-text {
                            display: block;
                            opacity: 1;
                        }
                    }
                    &.active {
                        a {
                            background: #e5e5e5;
                            &::before {
                                background: $theme-color-02;
                            }
                            i {
                                border-color: black;
                            }
                        }
                    }
                }
            }
        }
        .sidenav-main {
            margin-left: 230px;
        }
    }
}
.rownd-personal-data-center-header-bar {
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c1c1c1;
    .sidebar-menu-icon {
        border: none;
        background: transparent;
        margin: 17px;
        margin-bottom: 5px;
        .menu-icon {
            display: block;
            width: 22px;
            height: 2px;
            margin-bottom: 3px;
            background: black;
            position: relative;
        }
    }
    ul {
        li {
            &.border-bottom-menu {
                border-bottom: 1px solid #d0d0d0;
            }
            a {
                i {
                    display: flex;
                    width: 35px;
                    height: auto;
                    overflow: hidden;
                    // border: 1px solid transparent;
                    border-radius: 1000px;
                    padding: 2px;
                    img {
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &.active {
                a {
                    background: #e5e5e5;
                    &::before {
                        background: $theme-color-02;
                    }
                    i {
                        border-color: black;
                    }
                }
            }
        }
    }
}

.rownd-sidenav-toggle {
    .rownd-main {
        margin-left: 250px !important;
    }
    .sidebar-menu-icon {
        .menu-icon {
            &:first-child {
                transform: rotate(45deg);
                top: 8px;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
                opacity: 0;
            }

            &:last-child {
                transform: rotate(-45deg);
                top: -7px;
                margin-bottom: 0px;
            }
        }
    }
}
