@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import '../../../variable.scss';

@keyframes hub-loading-circle {
  from {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.hub-auth-v2__dark-mode {
  .hub-auth-v2__container {
    .rph-lottie-svg {
      svg {
        .rph-lottie-dark-mode {
          fill: $color-black !important;
        }
        #rph-lottie-primary-color {
          fill: var(--primary-color-dark, $color-purple-light);
        }
      }
    }
    .hub-auth-v2__footer__text {
      color: var(--primary-color-dark, $color-purple-light);
    }
  }

  .hub-auth-v2 {

    &__background {
      background-color: $black;
      opacity: 1;
    }
    &__container {
      background-color: #262626;
    }
    &__verify-icon-background {
      background-color: $black;
      opacity: 0.3;
    }
    &__button {
      background-color: var(--primary-color-dark, $color-purple-light);
      color: var(--background-color, #262626);
    }
    &__button-link {
      color: var(--primary-color-dark, $color-purple-light);
    }
    &__header {
      color: #f4f4f4;
    }

    &__failure-content {
      p {
        color: #c6c6c6;
      }
    }
    &__verified-content {
      a {
        color: var(--primary-color-dark, $color-purple-light);
      }
      p {
        color: #c6c6c6;
      }
    }

    &__swoops {
      color: $black;
      pointer-events: none;
    }

    &__swoops-mobile {
      color: $black;
      pointer-events: none;
    }
    &__rownd-icon {
      opacity: 0.5;
      filter: grayscale(100%) brightness(0%);
    }
  }
}

.hub-auth-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;

  .rph-lottie-svg {
    svg {
      transform: scale(2.1) !important;
      #rph-lottie-primary-color {
        fill: var(--primary-color, $color-primary);
      }
    }
  }

  a {
    color: var(--primary-color, $color-primary);
  }

  &__logo {
    max-width: 204px;
    max-height: 88px;
    margin-bottom: 40px;
  }

  &__button {
    background-color: var(--primary-color, $color-primary);
    border: none;
    outline: none;
    color: $color-white;
    padding: 5px 40px;
    border-radius: $border-radius-40;
    height: 40px;
    margin: 5px;
  }

  &__button-link {
    background: none;
    border: none;
    outline: none;
    color: var(--primary-color, $color-primary);
    border-radius: $border-radius-40;
    height: 40px;
    margin: 5px;
  }

  .hub-auth-v2__footer__text {
    color: var(--primary-color, $color-primary);
    margin-top: 20px; 
  }

  p {
    font-size: $font-size_400;
    max-width: 460px;
    text-align: center;
  }

  a {
    margin-top: 16px;
  }

  span.hub-loading-circle {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    border: 3px #d9dde4 solid;
    border-top: 3px var(--primary-color, $color-primary) solid;
    border-left: 3px var(--primary-color, $color-primary) solid;
    border-radius: 50%;
    animation: hub-loading-circle 2s infinite linear;
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $color-primary;
    z-index: 1;
    opacity: 0.08;
  }

  &__header {
    font-size: 28px;
    color: #161616;
    margin-bottom: 10px;
    text-align: center;
  }

  &__verifying-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 64px;
  }

  &__verify-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    color: $color-primary;
    margin-top: 16px;
    margin-bottom: 40px;
  }

  &__verify-icon-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-primary;
    opacity: 0.2;
    z-index: 1;
  }

  &__failure-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 64px;
  }

  &__verified-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 64px;
    width: 95%;
  }

  &__failure-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 64px;
    text-align: center;
  }

  &__container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin-top: 60px;
    width: 95%;
    height: 100%;
    max-width: 763px;
    border-top-left-radius: $border-radius-48;
    border-top-right-radius: $border-radius-48;
    background-color: $color-white;

    -webkit-box-shadow: 0px 2px 42px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 42px 0px rgba(0, 0, 0, 0.5);
  }

  &__swoops {
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-width: 763px;
    pointer-events: none;
    color: var(--primary-color, $color-primary);
    svg {
      position: absolute;
      bottom: 0px;
    }
  }

  &__rownd-icon {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    opacity: 0.3;
    filter: grayscale(100%);
  }

  &__swoops-mobile {
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-width: 763px;
    display: none;
    color: var(--primary-color, $color-primary);
    svg {
      position: absolute;
      bottom: 0px;
    }
  }

  @media screen and (max-width: 320px) {
    &__swoops {
      display: none;
    }
    &__swoops-mobile {
      display: flex;
    }
  }

  @media screen and (max-width: 450px) {
    &__container {
      margin-top: 0px;
      width: 100%;
      max-width: 100%;
      border-radius: 0px;
    }
  }
}
