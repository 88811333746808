@import '../../../variable.scss';

.pdc-button {
  width: 100%;
  border: none;
  outline: none;
  font-size: $font-size_300;
  padding: 9px;
  border-radius: 10px;

  &.pdc-button--tertiary {
    border: 1px solid var(--primary-color, $color-primary);
    background-color: transparent;
    color: var(--primary-color, $color-primary);

    &:active:not([disabled]) {
      opacity: 0.4;
    }

    &:focus-visible:not([disabled]) {
      border: 1px solid $color-white;
      background-color: var(--primary-color, $color-primary);
      color: $color-white;
      outline: 2px solid var(--primary-color, $color-primary);
    }
  }

  @media (hover: hover) {
    &.pdc-button:not([disabled]) {
      &.pdc-button--tertiary {
        &:hover {
          background-color: var(--primary-color, $color-primary);
          color: $color-white;
        }
      }
    }
  }
}

.hub-auth-v2__dark-mode {
  .pdc-button {
    &.pdc-button--tertiary {
      border: 1px solid var(--primary-color-dark, $color-purple-light);
      color: var(--primary-color-dark, $color-purple-light);

      &:focus-visible:not([disabled]) {
        background-color: var(--primary-color-dark, $color-purple-light);
        outline: 2px solid var(--primary-color-dark, $color-purple-light);
      }

      @media (hover: hover) {
        &.pdc-button:not([disabled]) {
          &:hover {
            background-color: var(--primary-color-dark, $color-purple-light);
            color: var(--background-color, #262626);
          }
        }
      }
    }
  }
}
