@import "variable.scss";

@import "~bootstrap/scss/bootstrap";

html {
    height: 100%;
}
body{
    font-family: $Roboto, sans-serif !important;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    // background: #ffe600;
    background: #ffffff;
    overflow-x: hidden;
    height: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    list-style-type: none;
}
img{
    max-width: 100%;
}
// a{
//     text-decoration: none !important;
//     list-style-type: none !important;
//     transition: all 0.3s ease-in-out;
//     &:hover{
//         opacity: 0.8;
//     }
// }
a,
button,
input[type="submit"] {
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out  !important;
    -moz-transition: all 0.3s ease-in-out  !important;
    -o-transition: all 0.3s ease-in-out  !important;
    display: inline-block;
    &:hover,
    &.active,
    &:focus,
    &:active {
        text-decoration: none;
        outline: none;
        opacity: 0.8;
        box-shadow: none !important;
    }
}

#root{
    height: 100%;
}
.app-main{
    height: 100%;
    overflow: auto;
}
main{
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

input,
select {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
// commen css file //
.rownd-login-titel-main{
    font-weight: 600;
    font-size: 32px;
    color: #3E3E3E;
    @include size991{
        font-size: 27px;
    }
    @include size767{
        font-size: 21px;
    }
    @include size575{
        font-size: 18px;
    }
}
.rownd-theme-btns{
    font-size: 16px;
    font-weight: normal;
    outline: none;
    box-shadow: none;
}
.rownd-theme-btns-main{
    font-size: 16px;
    background: $theme-color-02;
    font-weight: normal;
    color: white !important;
    outline: none;
    box-shadow: none;
    border: none;
    transition: all 0.3s ease-in-out;
    border-radius: 100px;
    // padding: 12px 24px;
    padding: 8px 24px;
    min-width: 190px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $theme-color-02;
    @include size767{
        font-size: 14px;
        min-width: 150px;
    }
    @include size575{
        min-width: 110px;
        padding: 6px 15px;
    }
    &:hover{
        opacity: 0.8;
    }
    &.rownd-cancel-btn{
        border: 2px solid $theme-color;
        background: transparent;
        color: $theme-color !important;
        transition: all 0.3s ease-in-out;
        margin: 0 10px;
        &:hover{
            background: #ccb4ff;
            border: 2px solid #ccb4ff;
        }
    }
    &.rownd-disabled-btn {
      background: #dddddd;
      border: 2px solid #dddddd !important;
      color: black !important;
      cursor: not-allowed;
    }
}
.rownd-project-title-main{
    font-size: 28px;
    color: #000000;
    font-weight: normal;
    @include size1199{
        font-size: 25px;
    }
    @include size991{
        font-size: 22px;
    }
    @include size767{
        font-size: 18px;
    }
    @include size575{
        font-size: 16px;
    }
    .application-link {
      color:#5B13DF;
    }
}
.rownd-page-small-title{
    font-size: 21px;
    color: black;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 10px;
    @include size991{
        font-size: 19px;
    }
    @include size767{
        font-size: 16px;
    }
}
label{
    font-size: 14px;
    font-weight: normal;
    color: #131026;
    margin-bottom: 5px;
    line-height: normal;
}
.form-control {
    background: #F7F7F7;
    border: 1px solid #F7F7F7;
    height: 48px;
    font-size: 16px;
    color: #3E3E3E;
    font-weight: normal;
    @include size767{
        height: 42px;
    }
    &:focus{
        border-color: $theme-color-02 !important;
        background: #F7F7F7;
        box-shadow: none;
    }
}
.hs-auth-error,.text-danger{
    font-size: 12px;
    color: #131026 !important;
    font-weight: normal;
    line-height: normal;
    display: inherit;
    padding: 0;
    margin-top: 8px;
    position: relative;
    padding-left: 20px;
    text-align: left;
    &::after{
        position: absolute;
        content: '';
        right: 0;
        left: 0;
        top: -10px;
        background: red;
        width: 100%;
        height: 2px;
    }
    &::before{
        position: absolute;
        content: '';
        background: url(//media/imagesRequired-icon.svg) no-repeat;
        background-position: center;
        background-size: contain;
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}
::placeholder {
    color: #989CA2;
    opacity: 0.65; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #989CA2;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #989CA2;
}

.form-group{
    label{
        i{
            margin-left: 10px;
            width: 17px;
            height: 17px;
            display: inherit;
            img{
                width: 100%;
            }
        }
    }
    .css-2b097c-container{
        outline: none;
        box-shadow: none;
        width: 100%;
        .css-yk16xz-control{
            border-color: #F7F7F7;
            background: #F7F7F7;
            border-radius: 4px;
            width: 100%;
            height: 48px !important;
            font-size: 14px;
            font-weight: normal;
            min-height: unset !important;
            @include size767{
                height: 44px !important;
            }
            .css-g1d714-ValueContainer{
                .css-1wa3eu0-placeholder{
                    font-size: 14px;
                    font-weight: normal;
                    color: #000000;
                }
            }
        }
        .css-1pahdxg-control{
            border-color: $theme-color-02;
            background: #F7F7F7;
            box-shadow: none;
            border-radius: 4px;
            height: 48px !important;
            font-size: 14px;
            font-weight: normal;
        }
    }
    // .btn{
    //     height: 48px !important;
    //     border-radius: 4px;
    //     padding: 8px;
    //     border: none;
    //     width: 100% !important;
    //     &.btn-primary{
    //         background: $theme-color-02 !important;
    //     }
    //     &.btn-danger{
    //         padding: 10px;
    //         background: #ff0f0f;
    //     }
    //     .toggle-group{
    //         span{
    //             h2{
    //                 font-size: 16px;
    //                 font-family: $CircularStdBook;
    //                 font-weight: normal;
    //                 padding-left: 6px;
    //             }
    //         }
    //     }
    // }
    .row{
        align-items: center;
    }
    .btn{
        height: 38px !important;
        border-radius: 4px;
        padding: 8px;
        border: none;
        color: #000000;
        font-size: 14px;
        font-weight: normal;
        outline: none !important;
        box-shadow: none !important;
        // &.btn-primary{
        //     background: transparent;
        // }
        // &.btn-danger{
        //     background: transparent;
        // }
        .toggle-group{
            width: 100% !important;
            border-radius: 4px;
            left: 0 !important;
            span{
                &.toggle-on{
                    padding: 10px;
                    // background: white;
                    color: black;
                    border-radius: 4px 0px 0px 4px;
                }
                &.toggle-off{
                    padding: 10px;
                    // background: #D8D8D8;
                    color:black;
                    border-radius: 0 4px 4px 0;
                }
                h2{
                    font-size: 12px;
                    font-weight: normal;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &.hs-map-switch{
            width: 100% !important;
            border: 1px solid #e8e8e8;
            &.btn-primary{
                background: transparent;
                .toggle-group{
                    .toggle-on{
                        background: #5B13DF;
                        color: white;
                    }
                    .toggle-off{
                        background: white;
                    }
                }
            }
            &.off{
                &.btn-danger{
                    background: transparent;
                    .toggle-group{
                        .toggle-on{
                            background: white;
                        }
                        .toggle-off{
                            background: #5B13DF;
                            color: white!important;
                        }
                    }
                }
            }
        }
    }
    .css-14jk2my-container{
        .css-1fhf3k1-control{
            border-color: #DCDCDC;
            border-radius: 4px;
            width: 100%;
            height: 48px !important;
            font-size: 14px;
            font-weight: normal;
            min-height: unset !important;
            background: #e9ecef;
            .css-g1d714-ValueContainer{

            }
            .css-1hb7zxy-IndicatorsContainer{

            }
        }
    }
}
form{
    .rownd-forgot-password-link{
        display: flex;
        justify-content: flex-end;
        a{
            font-size: 16px;
            color: $theme-color-02;
            font-weight: normal;
        }
    }
}
.rownd-form-btn{
    margin-top: 35px;
    .rownd-theme-btns-main{
        color: white;
        &:hover{
            color: white !important;
        }
    }
}
.rownd-addapi-key-btns{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    @include size767{
        margin-top: 20px;
    }
    a, .rownd-see-code-btn{
        background: #FBFBFF;
        border: 1px solid #E9E9E9;
        border-radius: 3px;
        font-size: 16px;
        color: $theme-color-02;
        font-weight: normal;
        min-width: 160px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
    }
    .rownd-test-with-btn{
        background: transparent;
        border-radius: 100px;
        border-color: $theme-color-02;
        &:hover{
            background: $theme-color-02;
            color: $white;
        }
    }
    i{
        width: 14px;
        height: 15px;
        overflow: hidden;
        margin-left: 8px;
        display: inline-flex;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}
.rownd-table-btns-block{
    background: #FBFBFF !important;
    border: none;
    border: 1px solid #E9E9E9;
    margin-right: 10px;
    padding: 4px 15px;
    color: $theme-color-02;
    font-size: 14px;
    font-weight: normal;
}
.rownd-table-details-section-main{
    // overflow-y: auto;
    &.rownd-billing-section {
      height: 200px;
      overflow-y: scroll;
    }
    table, .rownd-table{
        width: 100%;
        tbody, .rownd-table-body{
            .justification {
                width: 35%;
            }
            tr, .rownd-row{
                th,td, .rownd-col{
                    color: #000000;
                    font-size: 14px;
                    font-weight: normal;
                    padding: 0;
                    border-bottom: 1px solid #e8e8e8;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    padding-right: 10px;
                }
                .rownd-project-securely-table{
                    p {
                      width: 500px;
                      text-align: right;
                    }
                    display: flex;
                    align-items: center;
                    float: right;
                    .shared-false{
                        position: relative;
                        font-size: 14px;
                        font-weight: normal;
                        color: #131026;
                        line-height: normal;
                        padding-right: 27px;
                        margin-right: 15px;
                        &::before{
                            position: absolute;
                            content: '';
                            background: url(//media/imagesinfo-grey.svg) no-repeat;
                            background-position: center;
                            background-size: contain;
                            width: 16px;
                            height: 16px;
                            top: 1px;
                            right: 0;
                            margin: auto;
                        }
                    }
                    .required-true {
                      position: relative;
                      font-size: 14px;
                      font-weight: normal;
                      color: #131026;
                      line-height: normal;
                      padding-right: 27px;
                      margin-right: 15px;
                      &::before{
                          position: absolute;
                          content: '';
                          background: url(//media/imageslock.svg) no-repeat;
                          background-position: center;
                          background-size: contain;
                          width: 16px;
                          height: 16px;
                          top: 1px;
                          right: 0;
                          margin: auto;
                      }
                  }
                }
            }
            tr{
                td{
                    .rownd-copy-btns{
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin-left: 15px;
                        i{
                            width: 15px;
                            color: $theme-color-02;
                        }
                    }
                    .rownd-table-btns-block{
                        background: #FBFBFF !important;
                        border: none;
                        border: 1px solid #E9E9E9;
                        margin-right: 10px;
                        padding: 4px 15px;
                        color: $theme-color-02;
                        font-size: 14px;
                        font-weight: normal;
                        @include size767{
                            margin-bottom: 5px;
                        }
                        i{
                            color: $theme-color-02;
                            font-size: 15px;
                        }
                    }
                    .rownd-billing-history-bownload{
                        color: $theme-color-02;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
            }
            tr{
                .rownd-email-custom{
                    width: 320px;
                    max-width: 100%;
                }
                .rownd-select-box-main{
                    .css-2b097c-container{
                        outline: none;
                        box-shadow: none;
                        width: 100%;
                        .css-yk16xz-control{
                            border-color: #F7F7F7;
                            background: #F7F7F7;
                            border-radius: 4px;
                            width: 100%;
                            height: 38px;
                            .css-g1d714-ValueContainer{
                                .css-1wa3eu0-placeholder{
                                    font-size: 14px;
                                    font-weight: normal;
                                    color: #000000;
                                }
                            }
                        }
                        .css-1pahdxg-control{
                            border-color: $theme-color-02;
                            box-shadow: none;
                            border-radius: 4px;
                            background: #F7F7F7;
                        }
                    }
                    .btn{
                        height: 38px !important;
                        border-radius: 4px;
                        padding: 8px;
                        border: none;
                        color: #000000;
                        font-size: 14px;
                        font-weight: normal;
                        outline: none !important;
                        box-shadow: none !important;
                        // &.btn-primary{
                        //     background: transparent;
                        // }
                        // &.btn-danger{
                        //     background: transparent;
                        // }
                        .toggle-group{
                            width: 100% !important;
                            border-radius: 4px;
                            left: 0;
                            span{
                                &.toggle-on{
                                    padding: 10px;
                                    // background: white;
                                    color: black;
                                    border-radius: 4px 0px 0px 4px;
                                }
                                &.toggle-off{
                                    padding: 10px;
                                    // background: #D8D8D8;
                                    color: rgba(0, 0, 0, 0.5);
                                    border-radius: 0 4px 4px 0;
                                }
                                h2{
                                    font-size: 12px;
                                    font-weight: normal;
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        &.hs-map-switch{
                            width: 100px !important;
                            border: 1px solid #e8e8e8;
                            &.btn-primary{
                                background: transparent;
                                .toggle-group{
                                    .toggle-on{
                                        background: #5B13DF;
                                        color: white;
                                    }
                                    .toggle-off{
                                        background: white;
                                    }
                                }
                            }
                            &.off{
                                &.btn-danger{
                                    background: transparent;
                                    .toggle-group{
                                        .toggle-on{
                                            background: white;
                                        }
                                        .toggle-off{
                                            background: #D8D8D8;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .rownd-toggle-btns{
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;
                        .css-2b097c-container{
                            .css-yk16xz-control{
                                width: calc(100% - 15px);
                                margin-left: 10px;
                            }
                            .css-1pahdxg-control{
                                width: calc(100% - 15px);
                                margin-left: 10px;
                            }
                            .css-26l3qy-menu{
                                width: calc(100% - 15px);
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
// commen css file //

// Modal css file //
.rownd-alls-modal-main{
    .modal-content{
        border: none;
        outline: none;
        box-shadow: none;
        // @include size575{
        //     margin: auto 10px auto 15px;
        //     width: 90%;
        // }
        .modal-header{
            .modal-title{
                font-size: 18px;
                font-weight: normal;
                color: #131026;
            }
            button{
                outline: none;
                box-shadow: none;
                span{
                    font-size: 25px;
                    font-weight: 100;
                    color: #738498;
                }
            }
        }
        .modal-body{
            h6{
                font-size: 22px;
                font-weight: normal;
                color: #131026;
                text-align: center;
                width: 400px;
                margin: auto;
                max-width: 100%;
                padding: 15px 0;
                .rownd-applictaion-name{
                  font-weight: 800;
                }
            }
            .rownd-copy-link-block-cover{
                position: relative;
                .rownd-copy-btns{
                    background: transparent;
                    border: none;
                    padding: 0;
                    position: absolute;
                    right: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    i{
                        display: inline-table;
                        font-size: 15px;
                        font-weight: 600 !important;
                        color: $theme-color-02;
                        width: auto;
                    }
                }
            }
            .rownd-manage-ac-details{
                .rownd-manage-ac-details-list{
                    margin-bottom: 20px;
                    span{
                        font-size: 16px;
                        font-weight: normal;
                        opacity: 0.5;
                        color: black;
                    }
                    .rownd-remove-and-primary{
                        display: flex;
                        justify-content: flex-end;
                        .rownd-table-btns-block{
                            opacity: 0.5;
                            color: black;
                        }
                    }
                }
            }
            .rownd-enable-support-modal{
                canvas{
                    margin: auto;
                    text-align: center;
                    display: flex;
                    margin-bottom: 15px;
                }
                ul{
                    li{
                        display: block;
                        font-size: 14px;
                        font-family: $AvenirLTStd;
                        line-height: 20px;
                        margin-bottom: 15px;
                        position: relative;
                        padding-left: 16px;
                        &::before {
                            position: absolute;
                            content: '';
                            left: 0;
                            top: 6px;
                            margin: auto;
                            width: 6px;
                            height: 6px;
                            background: black;
                            border-radius: 1000px;
                        }
                        .these-options-link{
                            color: $theme-color-02;
                        }
                    }
                }
                p{
                    font-size: 15px;
                    color: black;
                    line-height: 20px;
                    margin-bottom: 20px;
                }
            }
            .enter-code-block{
                padding: 20px 0;
                border-top: 1px solid #e2e2e2;
                border-bottom: 1px solid #e2e2e2;
                text-align: center;
                p{
                    font-size: 15px;
                    color: black;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
                .form-control{
                    width: 200px;
                    margin: auto;
                }
            }
            .css-2b097c-container{
                outline: none;
                box-shadow: none;
                width: 100%;
                &.rownd-add-app {
                  .css-yk16xz-control{
                    height: 40px !important;
                  }
                }
                .css-yk16xz-control{
                    border-color: #F7F7F7;
                    background: #F7F7F7;
                    border-radius: 4px;
                    width: 100%;
                    height: 48px !important;
                    font-size: 14px;
                    font-weight: normal;
                    min-height: unset !important;
                    @include size767{
                        height: 44px !important;
                    }
                    .css-g1d714-ValueContainer{
                        .css-1wa3eu0-placeholder{
                            font-size: 14px;
                            font-weight: normal;
                            color: #000000;
                        }
                    }
                }
                .css-1pahdxg-control{
                    border-color: $theme-color-02;
                    background: #F7F7F7;
                    box-shadow: none;
                    border-radius: 4px;
                    height: 48px !important;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
        .modal-footer{
            justify-content: flex-end;
            border: none;
            padding: 5px 15px 20px;
            .rownd-theme-btns-main{
                padding: 10px 24px;
                min-width: 140px;
                margin: 0 8px;
                &.rownd-cancel-btn{
                    border: 2px solid $theme-color;
                    background: transparent;
                    color: $theme-color !important;
                    transition: all 0.3s ease-in-out;
                    &:hover{
                        background: #ccb4ff;
                        border: 2px solid #ccb4ff;
                    }
                }
                i {
                    margin-left: 10px;
                }
            }
        }
        .rownd-delete-datatype-footer{
            justify-content: space-between;
            .rownd-delete-link{
                font-size: 14px;
                font-weight: normal;
                color: #ee0005;
            }
        }
        .rownd-add-rownd-date-table{
            h4{
                font-size: 15px;
                font-weight: normal;
                color: #131026;
                margin-bottom: 0px;
            }
            .rownd-data-set-block-main{
                padding: 12px;
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                height: 100%;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                margin-top: 10px;
                p{
                    color: #505050;
                    font-size: 14px;
                    line-height: 20px;
                }
                &:hover{
                    border-color: $theme-color-02;
                }
            }
            .rownd-starter-data-set-section{
                height: auto;
                overflow: auto;
                .rownd-back-arrow{
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    cursor: pointer;
                    button{
                        outline: none;
                        box-shadow: none;
                        border: none;
                        background: transparent;
                        padding: 0;
                        margin-right: 15px;
                        img{
                            transform: rotate(180deg);
                            width: 26px;
                            height: 14px;
                        }
                    }
                    p{
                        font-size: 14px;
                        font-weight: normal;
                        color: #858990;
                    }
                }
                .accordion{
                    .card{
                        border: none;
                        margin-bottom: 6px;
                        .rownd-accordion-header-bar{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;
                            .card-header{
                                width: calc(100% - 30px);
                                border: none;
                                padding: 6px 30px 6px 12px;
                                position: relative;
                                font-size: 13px;
                                &::before{
                                    position: absolute;
                                    content: '';
                                    background: url(//media/imagesarrow-down-sign.svg) no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    width: 17px;
                                    height: 10px;
                                    top: 0;
                                    right: 10px;
                                    margin: auto;
                                    bottom: 0;
                                    transition: all 0.5s ease-in-out;
                                }
                            }
                            &.open{
                                .card-header{
                                    &::before{
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            .rownd-close-icon{
                                button{
                                    outline: none;
                                    box-shadow: none;
                                    width: 21px;
                                    height: 21px;
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 13px;
                                    line-height: 15px;
                                    border-radius: 100px;
                                    border: 1.5px solid #858990;
                                    color: #858990;
                                    background: transparent;
                                }
                            }
                        }
                        .rownd-add-data-main{
                            width: calc(100% - 30px);
                            background-color: rgba(0, 0, 0, 0.03);
                            .collapse{
                                .card-body{
                                    padding: 0 1rem;
                                    label{
                                        font-size: 13px;
                                        margin-bottom: 3px;
                                    }
                                    .form-group{
                                        margin-bottom: 0.3rem;
                                        .form-control{
                                            background: #ffffff;
                                            border: 1px solid #ffffff;
                                        }
                                        textarea{
                                            height: auto;
                                            font-size: 13px;
                                        }
                                    }
                                    .css-2b097c-container{
                                        .css-yk16xz-control{
                                            border-color:#ffffff;
                                            background:#ffffff;
                                        }
                                    }
                                    .css-1fhf3k1-control{
                                        height: 40px !important;
                                    }
                                }
                            }
                            .css-yk16xz-control{
                                height: 40px !important;
                            }
                            .css-1pahdxg-control{
                                height: 40px !important;
                            }
                        }
                    }
                }
                .rownd-add-another-app{
                    font-size: 14px;
                    font-weight: normal;
                    color: #3e3e3e;
                    outline: none;
                    box-shadow: none;
                    border: none;
                    background: transparent;
                    transition: all 0.3s ease-in-out;
                    margin-top: 10px;
                    &:hover{
                        opacity: 0.7;
                    }
                    .fa-plus{
                        border: 2px solid $theme-color-02;
                        border-radius: 100px;
                        width: 21px;
                        height: 21px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 12px;
                        color: $theme-color-02;
                        padding-top: 1px;
                        &::before{
                            content: "\f067";
                            font-size: 12px;
                            line-height: normal;
                            margin-left: 1px;
                        }
                    }
                }
                .rownd-add-another-data-type-block{
                    .card{
                        width: calc(100% - 30px);
                        background-color: rgba(0, 0, 0, 0.03);
                        .rownd-accordion-header-bar{
                            // justify-content: flex-end;
                            padding: 10px 30px 6px 12px;
                            padding-bottom: 0;
                        }

                        .card-body{
                            .form-group{
                                .form-control{
                                    background: #ffffff;
                                    border: 1px solid #ffffff;
                                }
                            }
                            .css-2b097c-container{
                                .css-yk16xz-control{
                                    border-color:#ffffff;
                                    background:#ffffff;
                                }
                            }
                            .btns-rownd-save{
                                display: flex;
                                justify-content: flex-end;
                                .rownd-theme-btns-main{
                                    &.rownd-cancel-btn{
                                        padding: 4px 14px;
                                        min-width: 130px;
                                        margin: 0;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
    &.rownd-delete-API-modal{
        .modal-body{
            .delete-API-modal-text{
                width: 100%;
                text-align: start;
            }
        }
        .modal-footer{
            justify-content: center;
            .rownd-theme-btns-main{
                background: #A51F22;
                border-color: #A51F22;
                &.rownd-cancel-btn{
                    border-color: #9a9a9a;
                    color: #9a9a9a !important;
                    &:hover{
                        background: #ffcecf;
                        border-color: #ffcecf;
                        color: #A51F22 !important;
                    }
                }
                &.rownd-main-btn{
                  border-color: #5B13DF;
                  background: #5B13DF;
                  color: white !important;
                  // &:hover{
                  //     background: #ffcecf;
                  //     border-color: #ffcecf;
                  //     color: #A51F22 !important;
                  // }
              }
            }
        }
    }

    &.rownd-preview-modal {
        margin-top: 80px;
        padding-bottom: 80px;
        .modal-header {
            img {
                margin-left: 10px;
                margin-bottom: 4px;
                height: 18px;
            }
        }
        .modal-body {
            .rownd-image-box {
                img {
                    max-width: 100%;
                }
            }
            
        }
    }

    &.rownd-upload-data-resource {
        margin-top: 80px;
        padding-bottom: 80px;
    }

    .rownd-change-password-footer{
        .modal-footer{
            justify-content: center;
        }
    }
    &.crop-images-modal{
        .crop-images-modal-main{
            width: 100%;
            height: 300px;
            position: relative;
            .crop-container{
                height: 100%;
            }
            .controls{
                right: 0;
                left: 0;
                margin: auto;
                bottom: -90px;
            }
        }
        .modal-footer{
            padding-top: 15px;
        }
    }
    &.rownd-team-plan-modal-main{
        .modal-body{
            padding: 0;
        }
        .rownd-team-plan{
            position: relative;
            .rownd-plan-modal-close-btn{
                position: absolute;
                right: 0;
                z-index: 2;
                top: 0;
                padding: 12px 15px;
                span{
                    font-size: 25px;
                    line-height: 25px;
                    font-weight: 100;
                    color: white;
                    cursor: pointer;
                }
            }
            .rownd-team-plan-modal-main{
                padding: 25px;
                .modal-title{
                    margin-bottom: 15px;
                }
                p{
                    font-size: 16px;
                    font-weight: 500;
                    padding-left: 30px;
                    display: block;
                    margin-bottom: 15px;
                    &.right-sing{
                        position: relative;
                        color: black;
                        &::before{
                            position: absolute;
                            content: '';
                            top: 0px;
                            left: 0;
                            margin: auto;
                            width: 20px;
                            height: 20px;
                            background: url(//media/imagescheckbox-icon.svg) no-repeat;
                            background-position: center;
                            background-size: contain;
                        }
                    }
                }
                .rownd-btns{
                    margin-top: 40px;
                    .rownd-cancel-btn{
                        @include size991{
                            margin: 0;
                            margin-bottom: 15px;
                        }
                        @include size767{
                            margin-right: 10px;
                        }
                    }
                }
            }
            .rownd-plan-modal-logo-section{
                background: $theme-color-02;
                padding: 35px;
                width: calc(100% + 1px);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 130px;
                    margin: auto;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
}
// Modal css file //

.loader {
  border: 5px solid #f3f3f3;
 -webkit-animation: spin 1s linear infinite;
 animation: spin 1s linear infinite;
 border-top: 5px solid #555;
 border-radius: 50%;
 width: 50px;
 height: 50px;
}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.Toastify__toast-body {
  font-size: 18px;
}


.rownd-manage-footer-link-main{
    display: inline-flex;
    align-items: center;
    p{
        font-size: 15px;
        color: black;
        font-weight: normal;
        line-height: normal;
        img{
            width: 15px;
            height: auto;
            object-fit: contain;
            object-position: center;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        a{
            font-size: 15px;
            color: $theme-color-02;
            text-decoration: underline;
        }
    }
}
.rownd-add-checkbox-main{
    .form-group{
        display: grid;
        align-items: center;
        input{
            width: 20px;
            height: 20px;
            opacity: 1;
        }
        label{
            margin: 0;
            margin-left: 8px;
        }
        .inputtext{
            width: 100%;
            padding: 5px 15px;
            height: 50px;
        }

    }
}
.rownd-start-building-cy-block{
    .rownd-start-building-cy-details{
        background: #FFFFFF;
        padding: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: flex-end;
        @include size991{
            padding: 20px;
        }
        .rownd-building-icon{
            width: 100px;
            height: 100px;
            overflow: hidden;
            background: #e4e4e4;
            border-radius: 1000px;
            margin-right: 15px;
        }
        .rownd-grey-box-img{
            display: block;
            width: 100%;
            .rownd-title{
                margin-bottom: 15px;
                span{
                    width: 60%;
                    background: #e4e4e4;
                    display: block;
                    height: 20px;
                    margin-bottom: 15px;
                    &:last-child{
                        width: 100%;
                        background: #f1f1f1;
                    }
                }
                &.step-two-main{
                    display: flex;
                    justify-content: space-between;
                    width: 92%;
                    margin-bottom: 0px;
                    span{
                        width: 30%;
                        &:last-child{
                            width: 50%;
                            background: #e4e4e4;
                        }
                    }
                }
                &.three-box{
                    width: 88%;
                    span{
                        width: 60%;
                        margin-bottom: 0;
                        &:last-child{
                            width: 30%;
                            background: #e4e4e4;
                        }
                    }
                }
            }
        }
    }
}
.Toastify{
    .Toastify__toast-container{
        z-index: 999999;
        .Toastify__toast{
            background: white;
            position: relative;
            padding: 15px;
            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                background: green;
                right: 0;
                left: 0;
                top: 0;
            }
            .Toastify__toast-body{
                ul{
                    li{
                        color: black;
                        font-size: 14px;
                    }
                }
                div{
                    width: 100%;
                    display: block;
                    h5{
                        font-size: 16px;
                        color: black;
                        position: relative;
                        padding-left: 23px;
                        line-height: normal;
                        &::before{
                            position: absolute;
                            content: '';
                            left: 0;
                            top: 2px;
                            width: 15px;
                            height: 15px;
                        }
                    }
                    p{
                        font-size: 14px;
                        color: black;
                        padding-left: 23px;
                        line-height: normal;
                    }
                }
            }
            .Toastify__close-button{
                color: black;
                outline: none;
                box-shadow: none;
                font-weight: 100;
            }
            &.Toastify__toast--error{
                &::before{
                    background: red;
                }
                .Toastify__toast-body{
                    div{
                        h5{
                            &::before{
                                background: url(//media/imagesRequired-icon.svg) no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
            &.Toastify__toast--success{
                &::before{
                    background: #299354;
                }
                .Toastify__toast-body{
                    div{
                        h5{
                            &::before{
                                background: url(//media/imagessuccess.svg) no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
            &.Toastify__toast--info{
                &::before{
                    background: #1D71F8;
                }
                .Toastify__toast-body{
                    div{
                        h5{
                            &::before{
                                background: url(//media/imagesinfo-icon.svg) no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
        }
    }
}
.pagination-box-wrapper{
    justify-content: flex-end;
    li{
        .page-link{
            border: none;
            background: transparent;
            color: #000 !important;
        }
        &.active{
            .page-link{
                border: none;
                background: transparent;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 3px;
                    background: $theme-color-02;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
}