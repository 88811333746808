$black: #000;
$white:#fff;
$light-green:#57d371;
$dark-green:#39504b;
// $font-varela:'Varela Round';
// $font-circular:'CircularStd';


// theme variable list //
$theme-color: #3B0084;
$theme-color-02: #5B13DF;//#864DFF;
$CircularStd : 'Circular Std';
$CircularStdBook : 'Circular Std Book';
$AvenirLTStd : 'Avenir LT Std';
$Roboto : 'Roboto';

$color-primary: #5b13df;
$color-secondary: #330c91;
$color-purple-light: #c8aaff;
$color-purple-dark: #21085f;
$color-white: #fff;
$color-black: #000;
// theme variable list //

// font variable list //
$font-size_100: 0.625rem; //10px;
$font-size_200: 0.75rem; //12px;
$font-size_300: 0.875rem; //14px;
$font-size_400: 1rem; //16px;
$font-size_500: 1.25rem; //20px;
$font-size_600: 1.5rem; //24px;
$html-font-size: 16px;
// font variable list //

$border-radius-32: 8px;
$border-radius-40: 10px;
$border-radius-48: 12px;

@mixin size1440 {
    @media (max-width: 1440px) {
        @content;
    }
}
@mixin size1360 {
    @media (max-width: 1360px) {
        @content;
    }
}
@mixin size1160 {
    @media (max-width: 1160px) {
        @content;
    }
}

@mixin size1260 {
    @media (max-width: 1260px) {
        @content;
    }
}


@mixin size1199 {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin size991 {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin size767 {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin size575 {
    @media (max-width: 575px) {
        @content;
    }
}
@mixin size375 {
    @media (max-width: 375px) {
        @content;
    }
}