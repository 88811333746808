@import "../../../variable.scss";
.rownd-end-user-follow-main{
    .rownd-user-welcome-page-main{
        min-height: 100vh;
        padding: 90px 0;
        // background: $theme-color;
        .rownd-user-welcome-details{
            p{
                font-size: 18px;
                color: #3E3E3E;
            }
            .rownd-logo-block{
                text-align: center;
                margin-bottom: 35px;
                .rownd-user-logo{
                    width: 55px;
                    height: 55px;
                    overflow: hidden;
                    margin-bottom: 15px;
                    border-radius: 1000px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
            .rownd-user-profile-details{
                border-bottom: 1px solid rgba(201, 201, 201, 0.5);
                padding-bottom: 30px;
                .rownd-user-pic{
                    width: 120px;
                    height: 120px;
                    overflow: hidden;
                    text-align: center;
                    margin: auto;
                    margin-bottom: 20px;
                    background: white;
                    display: flex;
                    border-radius: 1000px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
            .rownd-verify-details-block{
                p{
                    padding: 30px 0 16px;
                }
                form{
                    .rownd-form-btn{
                        .rownd-theme-btns-main{
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.rownd-dark-bg-color{
            background: $theme-color;
            .rownd-user-welcome-details{
                p{
                    color: #ffffff;
                }
                .rownd-logo-block{
                    .rownd-login-titel-main{
                        color: #ffffff;
                    }
                }
                .rownd-verify-details-block{
                    form{
                        .form-group{
                            label{
                                color: #ffffff;
                            }
                            .form-control {
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }
}