@import '../../../variable.scss';
.rownd-welcome-footer-bar-main{
    background: #ECECEC;
    color: #666;
    font-size: 0.9rem;
    padding: 15px;
    position: absolute;
    bottom:0;
    left: 0;
    width: 100%;
    .rownd-rights-link{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include size575{
            flex-direction: column;
        }
    }
    .rownd-welcome-footer-details-main {
        color: #666;
        font-weight: normal;
        line-height: normal;
        transition: all 0.3s ease-in-out;
        &:hover{
            color: $theme-color-02;
        }
        &.hover-remove-class{
            &:hover{
                color: black;
            }
        }
        @include size575{
            margin-bottom: 10px;
        }
    }
    .wagdt-terms-link-bloc{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include size767{
            justify-content: center;
        }

        * {
            padding: 0 10px;
        }
    }
}