@import '../../../../variable.scss';

.hub-auth-v2__dark-mode {
  .pdc-not-working {
    background-color: var(--background-color, #262626);
    .pdc-not-working__title {
      color: $color-white;
    }

    .pdc-not-working__subtitle {
      color: $color-white;
    }

    .pdc-not-working__item {
      .pdc-not-working__item__text {
        color: $color-white;
        a {
          color: var(--primary-color-dark, $color-purple-light);
        }
      }

      .pdc-not-working__item__icon {
        .pdc-not-working__item__icon__background {
          background-color: $color-white;
          opacity: 0.1;
        }
        svg {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
      }
    }
  }
}

.pdc-not-working {
  max-width: 410px;

  .pdc-not-working__title {
    margin-bottom: 8px;
    font-size: $font-size_400;
    color: $color-black;
    font-weight: 500;
  }

  .pdc-not-working__subtitle {
    margin-bottom: 24px;
    font-size: $font-size_300;
    color: $color-black;
  }

  .pdc-not-working__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;

    .pdc-not-working__item__icon {
      position: relative;
      height: 64px;
      width: 64px;
      min-width: 64px;
      min-height: 64px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .pdc-not-working__item__icon__background {
        background-color: #f4f4f4;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      svg {
        height: 32px;
        width: 32px;
        z-index: 1;
      }
    }

    .pdc-not-working__item__text {
      color: $color-black;
      font-size: $font-size_300;
      margin-left: 16px;

      a {
        color: var(--primary-color, $color-primary);
        text-decoration: underline;
        margin-top: 0px;
        display: inline;
      }
    }
  }
}
